<script>
import AlarmRuleSet from "@/views/collision-alarm/alarm-rule/AlarmRuleSet";
import AlarmRuleDetail from "@/views/collision-alarm/alarm-rule/AlarmRuleDetail";
import { enumEnableStatus, getLabelByValue } from "@/utils/EnumUtils";
// import CommonApi from '@/api/CommonApi'
// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
import DataTransformUtils from "@/utils/DataTransformUtils";
import { Debounce } from "@/utils/OperateUtils";
export default {
  name: "AlarmRule",
  components: {
    AlarmRuleSet,
    AlarmRuleDetail,
  },
  data() {
    return {
      tableHeight: 200,
      organStructure: [],
      enumAlarmType: [],
      enumAlarmGrade: [],
      enumStateGrade: [],
      enumEnableStatus: enumEnableStatus,
      getLabelByValue: getLabelByValue,
      formSearch: {
        warnName: "",
        warnType: "",
        warnLevel: "",
        orgId: [],
        enable: "",
      },
      searchData: {
        baseId: "",
        isIssue: "",
        warnName: "",
        warnType: "",
        warnLevel: "",
        orgId: "",
        enable: "",
        current: 1,
        size: 10,
      },
      tableLoading: false,
      tableData: [],
      total: 0,
      setVisible: false,
      detailVisible: false,
      selectRow: null,
    };
  },
  mounted() {
    // this.formSearch.orgId = [this.platformId];
    // this.searchData.orgId = this.platformId;
    this.searchData.baseId = this.$route.params.baseId || "";
    this.searchData.isIssue = this.$route.params.isIssue || "";
    this.getAlarmTypeList();
    this.getAlarmGradeList();
    this.getStateGradeList();
    // this.getOrganStructure();
    this.getAlarmRuleList();
    this.tableHeight = this.$refs.tableContainer.clientHeight - 98;
    window.addEventListener("resize", this.tableResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.tableResize);
  },
  computed: {
    platformId() {
      return this.$store.state.platform.id;
    },
  },
  methods: {
    getAlarmTypeList() {
      this.$api.getDictList("WARN_TYPE").then((response) => {
        if (response.code == 200) {
          this.enumAlarmType = response.data.sort((a, b) => a.sort - b.sort);
        }
      });
    },
    getAlarmGradeList() {
      this.$api.getDictList("WARN_LEVEL").then((response) => {
        if (response.code == 200) {
          this.enumAlarmGrade = response.data.sort((a, b) => a.sort - b.sort);
        }
      });
    },
    getStateGradeList() {
      this.$api.getDictList("WARN_SITUATION").then((response) => {
        if (response.code == 200) {
          this.enumStateGrade = response.data.sort((a, b) => a.sort - b.sort);
        }
      });
    },
    getOrganStructure() {
      this.$api.getOrganStructure().then((response) => {
        if (response.code == 200) {
          let arr = response.data.map((item) => ({
            value: item.id,
            label: item.orgName,
            parent: item.parentId || "",
            parents: item.parentIds || "",
            type: item.type,
          }));
          console.log(arr);

          let treeArr = DataTransformUtils.toTree(
            arr,
            "value",
            "parent",
            "children"
          );
          console.log(treeArr);
          this.organStructure = treeArr;

          // 只显示平台组织架构自身及以下数据
          // let stark = []
          // stark = stark.concat(treeArr)
          // while (stark.length) {
          // 	let temp = stark.shift()
          // 	if (temp.children) {
          // 		stark = stark.concat(temp.children)
          // 	}
          // 	if (temp.value == this.platformId) {
          // 		this.organStructure = [temp]
          // 	}
          // }
        }
      });
    },
    organFilter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    getAlarmRuleList() {
      this.tableLoading = true;
      this.$api
        .getAlarmRuleList(this.searchData)
        .then((response) => {
          if (response.code == 200) {
            this.tableData = response.data.records.map((item, index) => {
              item.key =
                (this.searchData.current - 1) * this.searchData.size +
                index +
                1;
              let templateArr = DataTransformUtils.convertRuleTemplate(
                item.warnRule
              ).map((titem) => {
                if (titem.type == "number") {
                  let ruleValue = JSON.parse(item.defaultValue);
                  return (
                    '<span style="color: #529aff; font-weight: bold;margin: 0 2px;">' +
                    (ruleValue[titem.index] || "__") +
                    "</span>"
                  );
                } else if (titem.type == "select") {
                  let ruleItems = JSON.parse(item.ruleItems);
                  let ruleValues = JSON.parse(item.defaultValue);
                  return (
                    '<span style="color: #529aff; font-weight: bold;margin: 0 2px;">' +
                    (ruleItems[titem.index]
                      .filter((item) => ruleValues[titem.index] == item.value)
                      .map((item) => item.option)
                      .toString() || "__") +
                    "</span>"
                  );
                } else if (titem.type == "multiSelect") {
                  let ruleItems = JSON.parse(item.ruleItems);
                  let ruleValues = JSON.parse(item.defaultValue);
                  return (
                    '<span style="color: #529aff; font-weight: bold;margin: 0 2px;">' +
                    (ruleItems[titem.index]
                      .filter((item) =>
                        ruleValues[titem.index].includes(item.value)
                      )
                      .map((item) => item.option)
                      .join("、") || "__") +
                    "</span>"
                  );
                } else {
                  return titem.value;
                }
              });
              item.warnRuleTemplate = templateArr.join("");
              return item;
            });
            this.total = response.data.total;
          } else {
            response.msg && this.$message.error(response.msg);
          }
          this.tableLoading = false;
        })
        .catch((err) => {
          err.message && this.$message.error(err.message);
          this.tableLoading = false;
        });
    },
    pageNumChange(current) {
      this.searchData.current = current;
      this.getAlarmRuleList();
    },
    pageSizeChange(current, size) {
      this.searchData.current = 1;
      this.searchData.size = size;
      this.getAlarmRuleList();
    },
    btnQuery: Debounce(function () {
      this.searchData.baseId = "";
      this.searchData.isIssue = "";
      this.searchData.warnName = this.formSearch.warnName;
      this.searchData.warnType = this.formSearch.warnType;
      this.searchData.warnLevel = this.formSearch.warnLevel;
      this.searchData.orgId = this.formSearch.orgId.length
        ? this.formSearch.orgId[this.formSearch.orgId.length - 1]
        : "";
      this.searchData.enable = this.formSearch.enable;
      this.searchData.current = 1;
      this.getAlarmRuleList();
    }),
    btnReset: Debounce(function () {
      this.searchData.baseId = "";
      this.searchData.isIssue = "";
      this.searchData.warnName = this.formSearch.warnName = "";
      this.searchData.warnType = this.formSearch.warnType = "";
      this.searchData.warnLevel = this.formSearch.warnLevel = "";
      // this.formSearch.orgId = [this.platformId];
      // this.searchData.orgId = this.platformId;
      this.searchData.enable = this.formSearch.enable = "";
      this.searchData.current = 1;
      this.getAlarmRuleList();
    }),
    // 启用 / 禁用
    enableOk: Debounce(function (record) {
      this.$api
        .enableAlarmRule({
          id: record.id,
        })
        .then((response) => {
          if (response.code == 200) {
            this.$message.success(
              `${record.enable == 0 ? "启用" : "禁用"}成功`
            );
            this.getAlarmRuleList();
          } else {
            response.msg && this.$message.error(response.msg);
          }
        })
        .catch((err) => {
          err.message && this.$message.error(err.message);
        });
    }),
    setModal(record) {
      this.selectRow = record;
      this.setVisible = true;
    },
    setOk() {
      this.setVisible = false;
      this.getAlarmRuleList();
    },
    setCancel() {
      this.setVisible = false;
    },
    detailModal(record) {
      this.selectRow = record;
      this.detailVisible = true;
    },
    detailCancel() {
      this.detailVisible = false;
    },
    tableResize() {
      this.tableHeight = this.$refs.tableContainer.clientHeight - 98;
    },
  },
};
</script>

<template>
  <div class="rate-manage-container">
    <!-- <a-breadcrumb class="container-breadcrumb">
			<a-breadcrumb-item>预警规则</a-breadcrumb-item>
		</a-breadcrumb> -->
    <div class="container-toolbar search-container" @keyup.enter="btnQuery">
      <div class="toolbar-item">
        <label class="toolbar-label">预警名称</label>
        <a-input
          class="toolbar-input"
          v-model="formSearch.warnName"
          allowClear
        />
      </div>
      <div class="toolbar-item">
        <label class="toolbar-label">预警类型</label>
        <a-select
          class="toolbar-select"
          placeholder="全部"
          v-model="formSearch.warnType"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option
            v-for="item in enumAlarmType"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="toolbar-item">
        <label class="toolbar-label">预警等级</label>
        <a-select
          class="toolbar-select"
          placeholder="全部"
          v-model="formSearch.warnLevel"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option
            v-for="item in enumAlarmGrade"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <!-- <div class="toolbar-item">
        <label class="toolbar-label">组织架构</label>
        <a-cascader
          placeholder="全部"
          :allowClear="false"
          :options="organStructure"
          :show-search="{ organFilter }"
          :change-on-select="true"
          :displayRender="({ labels }) => labels[labels.length - 1]"
          v-model="formSearch.orgId"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
        </a-cascader>
      </div> -->
      <div class="toolbar-item">
        <label class="toolbar-label">启用状态</label>
        <a-select
          class="toolbar-select"
          placeholder="全部"
          v-model="formSearch.enable"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option
            v-for="item in enumEnableStatus"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="toolbar-item">
        <a-button type="primary" @click="btnQuery" class="m-r-8">
            <a-icon type="search" />查询
          </a-button>
          <a-button @click="btnReset" class="m-r-8">
            <a-icon type="reload" />重置
          </a-button>

      </div>
      <div class="toolbar-item-right">
        <router-link :to="{ name: 'collision-alarm-AlarmRuleBase' }">
          <a-button class="toolbar-button" type="primary">
            预警规则库
          </a-button>
        </router-link>
      </div>
    </div>
    <div class="container-content" ref="tableContainer">
      <a-table
        class="alarm-table"
        :locale="{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' }"
        :scroll="{ y: tableHeight }"
        :columns="[
          {
            title: '序号',
            dataIndex: 'key',
            width: 90,
          },
          {
            title: '预警名称',
            dataIndex: 'warnName',
            ellipsis: true,
            width: 180,
          },
          {
            title: '碰撞预警规则',
            dataIndex: 'warnRuleTemplate',
            width: '65%',
            scopedSlots: { customRender: 'warnRuleTemplate' },
          },
          {
            title: '研判通知',
            dataIndex: 'warnNotify',
            width: '35%',
          },
          {
            title: '预警类型',
            dataIndex: 'typeName',
            align: 'center',
            width: 120,
            ellipsis: true,
          },
          {
            title: '预警等级',
            dataIndex: 'levelName',
            align: 'center',
            width: 120,
            ellipsis: true,
          },
          {
            title: '组织架构',
            dataIndex: 'orgName',
            align: 'center',
            width: 180,
            ellipsis: true,
          },
          {
            title: '是否启用',
            dataIndex: 'enable',
            align: 'center',
            ellipsis: true,
            width: 120,
            scopedSlots: { customRender: 'enable' },
          },
          {
            title: '操作',
            dataIndex: 'operation',
            align: 'center',
            width: 120,
            scopedSlots: { customRender: 'operation' },
          },
        ]"
        :data-source="tableData"
        :pagination="false"
      >
        <span slot="warnRuleTemplate" slot-scope="text" v-html="text"></span>
        <a-switch
          slot="enable"
          slot-scope="text, record"
          :checked="text == 1"
          @change="enableOk(record)"
        />
        <template slot="operation" slot-scope="text, record">
          <div class="table-operation">
            <a href="javascript:void(0)" @click="detailModal(record)"> 查看 </a>
            <label class="opeartion-line"></label>
            <a href="javascript:void(0)" @click="setModal(record)"> 配置 </a>
          </div>
        </template>
      </a-table>
    </div>
    <div class="content-page pagination-container" v-if="total">
      <a-pagination
        :showQuickJumper="true"
        :showSizeChanger="true"
        :current="searchData.current"
        :pageSize="searchData.size"
        :total="total"
        :show-total="(total) => `共${total}条`"
        @change="pageNumChange"
        @showSizeChange="pageSizeChange"
      />
    </div>
    <AlarmRuleSet
      :visible="setVisible"
      :formData="selectRow"
      @ok="setOk"
      @cancel="setCancel"
    ></AlarmRuleSet>
    <AlarmRuleDetail
      :visible="detailVisible"
      :formData="selectRow"
      @close="detailCancel"
    ></AlarmRuleDetail>
  </div>
</template>

<style lang="scss" scoped>
.alarm-table {
  /deep/ {
    .ant-table-tbody > tr > td {
      height: 96px;
    }
  }
}
</style>
