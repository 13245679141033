<script>
	import DataTransformUtils from '@/utils/DataTransformUtils'
	export default {
		name: 'RuleTemplate',
		props: {
			ruleTemplate: {
				type: String,
				default: ''
			},
			ruleItems: {
				type: String,
				default: ''
			},
			ruleValue: {
				type: String,
				default: ''
			},
			rangeValue: {
				type: String,
				default: ''
			}
		},
		computed: {
			ruleData() {
				let { ruleTemplate, ruleItems, ruleValue, rangeValue } = this
				let data = {
					template: DataTransformUtils.convertRuleTemplate(ruleTemplate),
					items: ruleItems ? JSON.parse(ruleItems) : [],
					value: ruleValue ? JSON.parse(ruleValue) : [],
					range: rangeValue ? JSON.parse(rangeValue) : []
				}
				return data
			}
		},
		methods: {
			valueChange(index, value) {
				this.$emit('change', {
					index: index,
					value: value
				})
			}
		}
	}
</script>

<template>
	<div class="rule-container">
		<template v-for="(item, index) in ruleData.template">
			<span v-if="item.type == 'string'" :key="index">
				{{ item.value }}
			</span>

			<!-- 数字输入框 -->
			<a-input-number
				v-else-if="item.type == 'number'"
				class="rule-input-number"
				:key="index"
				:min="
					ruleData.range[item.index] ? ruleData.range[item.index][0] : -Infinity
				"
				:max="
					ruleData.range[item.index] ? ruleData.range[item.index][1] : Infinity
				"
				:value="ruleData.value[item.index]"
				@change="(newValue) => valueChange(item.index, newValue)"
			/>

			<!-- 选择器 -->
			<a-select
				v-else-if="item.type == 'select'"
				class="rule-input-select"
				:placeholder="
					ruleData.items[item.index].length &&
					ruleData.items[item.index][0].value == ''
						? ruleData.items[item.index][0].option
						: '请选择'
				"
				:key="index"
				:value="ruleData.value[item.index]"
				@change="(newValue) => valueChange(item.index, newValue)"
			>
				<a-icon slot="suffixIcon" type="caret-down" />
				<template v-if="ruleData.items[item.index].length">
					<a-select-option
						v-for="oitem in ruleData.items[item.index]"
						:value="oitem.value"
						:key="oitem.value"
					>
						{{ oitem.option }}
					</a-select-option>
				</template>
			</a-select>

			<!-- 选择器(多选) -->
			<!-- <a-dropdown
				v-else-if="item.type == 'multiSelect'"
				:key="index"
				:trigger="['click']"
			>
				<a-input
					class="rule-input-select"
					:placeholder="
						ruleData.items[item.index].length &&
						ruleData.items[item.index][0].value == ''
							? ruleData.items[item.index][0].option
							: '请选择'
					"
					:value="ruleData.value[item.index]"
				>
					<a-icon slot="suffix" type="caret-down" />
				</a-input>
				<a-menu slot="overlay" :multiple="true">
					<a-menu-item>
						<a href="javascript:;">1st menu item</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;">2nd menu item</a>
					</a-menu-item>
					<a-menu-item>
						<a href="javascript:;">3rd menu item</a>
					</a-menu-item>
				</a-menu>
			</a-dropdown> -->
			<a-select
				v-else-if="item.type == 'multiSelect'"
				mode="multiple"
				class="rule-input-select"
				:placeholder="
					ruleData.items[item.index].length &&
					ruleData.items[item.index][0].value == ''
						? ruleData.items[item.index][0].option
						: '请选择'
				"
				:key="index"
				:value="ruleData.value[item.index]"
				@change="(newValue) => valueChange(item.index, newValue)"
			>
				<a-icon slot="suffixIcon" type="caret-down" />
				<template v-if="ruleData.items[item.index].length">
					<a-select-option
						v-for="oitem in ruleData.items[item.index]"
						:value="oitem.value"
						:key="oitem.value"
					>
						{{ oitem.option }}
					</a-select-option>
				</template>
			</a-select>
		</template>
	</div>
</template>

<style lang="scss" scoped>
	.rule-container {
		display: inline-block;
		line-height: 40px;
		.rule-input-number {
			width: 75px;
			margin: 0 5px;
		}
		.rule-input-select {
			width: 135px;
			margin: 0 5px;
		}
	}
	.multi-select {
		/deep/ {
			.ant-select-selection--multiple {
				height: 32px;
				.ant-select-selection__rendered {
					margin: 0 10px;
					& > ul > li {
						height: 30px;
						line-height: 30px;
						margin: 0;
						padding: 0;
						background: none;
						border: none;
						&:not(:first-child) {
							.ant-select-selection__choice__content {
								&:before {
									content: '、';
									display: inline-block;
								}
							}
						}
					}
				}
				& > ul > li {
					height: 30px;
					line-height: 30px;
					margin: 0;
					padding: 0;
					background: none;
					border: none;
				}
				.ant-select-selection__choice__content {
					color: #ffffff;
				}
				.ant-select-selection__choice__remove {
					display: none;
				}
				.ant-select-search--inline {
					display: none;
				}
			}
		}
	}
</style>
