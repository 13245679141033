<script>
	import RuleTemplate from './RuleTemplate'
	// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
	import { Debounce } from '@/utils/OperateUtils'
	export default {
		name: 'AlarmRuleSet',
		components: {
			RuleTemplate
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			formData: {
				type: Object
			}
		},
		data() {
			return {
				ruleValue: [''],
				editLoading: false
			}
		},
		watch: {
			visible(nVal) {
				this.editLoading = false
				if (nVal && this.formData) {
					this.ruleValue = this.formData.defaultValue || ''
				} else {
					this.ruleValue = ''
				}
			}
		},
		methods: {
			valueChange(changeData) {
				let newArr = JSON.parse(this.ruleValue)
				newArr[changeData.index] = changeData.value
				this.ruleValue = JSON.stringify(newArr)
			},
			btnConfirm: Debounce(function() {
				this.editLoading = true
				this.$api.setAlarmRule({
					id: this.formData.id,
					defaultValue: this.ruleValue
				})
					.then((response) => {
						if (response.code == 200) {
							this.$message.success('保存成功')
							this.$emit('ok')
						} else {
							response.msg && this.$message.error(response.msg)
						}
						this.editLoading = false
					})
					.catch((err) => {
						err.message && this.$message.error(err.message)
						this.editLoading = false
					})
			}),
			btnCancel() {
				this.$emit('cancel')
			}
		}
	}
</script>

<template>
	<a-drawer
		class="edit-drawer"
		title="预警规则配置"
		:width="480"
		:closable="false"
		:maskClosable="true"
		:visible="visible"
		@close="btnCancel"
	>
		<a-form
			:colon="false"
			:labelCol="{ span: 6 }"
			:wrapperCol="{ span: 18 }"
			:labelAlign="'right'"
		>
			<a-form-item label="预警名称">
				<label class="form-label" v-if="formData">
					{{ formData.warnName }}
				</label>
			</a-form-item>
			<a-form-item label="碰撞预警规则" required>
				<RuleTemplate
					v-if="formData"
					:ruleTemplate="formData.warnRule"
					:ruleItems="formData.ruleItems"
					:ruleValue="ruleValue"
					:rangeValue="formData.maxValue"
					@change="valueChange"
				></RuleTemplate>
			</a-form-item>
			<a-form-item label="研判通知">
				<label class="form-label" v-if="formData">
					{{ formData.warnNotify }}
				</label>
			</a-form-item>
			<a-form-item label="预警类型">
				<label class="form-label" v-if="formData">
					{{ formData.typeName }}
				</label>
			</a-form-item>
			<a-form-item label="预警等级">
				<label class="form-label" v-if="formData">
					{{ formData.levelName }}
				</label>
			</a-form-item>
			<a-form-item label="组织架构">
				<label class="form-label" v-if="formData">
					{{ formData.orgName }}
				</label>
			</a-form-item>
		</a-form>
		<div class="close-button">
			<i class="ali-icon ali-icon-action-close" @click="btnCancel"></i>
		</div>
		<div class="submit-button">
			<a-button :style="{ marginRight: '15px' }" @click="btnCancel">
				关闭
			</a-button>
			<a-button :loading="editLoading" type="primary" @click="btnConfirm">
				保存
			</a-button>
		</div>
	</a-drawer>
</template>

<style lang="scss" scoped>
	.edit-drawer {
		/deep/ {
			.ant-drawer-body {
				max-height: calc(100% - 116px);
			}
		}
	}
</style>
