var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule-container"},[_vm._l((_vm.ruleData.template),function(item,index){return [(item.type == 'string')?_c('span',{key:index},[_vm._v(" "+_vm._s(item.value)+" ")]):(item.type == 'number')?_c('a-input-number',{key:index,staticClass:"rule-input-number",attrs:{"min":_vm.ruleData.range[item.index] ? _vm.ruleData.range[item.index][0] : -Infinity,"max":_vm.ruleData.range[item.index] ? _vm.ruleData.range[item.index][1] : Infinity,"value":_vm.ruleData.value[item.index]},on:{"change":(newValue) => _vm.valueChange(item.index, newValue)}}):(item.type == 'select')?_c('a-select',{key:index,staticClass:"rule-input-select",attrs:{"placeholder":_vm.ruleData.items[item.index].length &&
				_vm.ruleData.items[item.index][0].value == ''
					? _vm.ruleData.items[item.index][0].option
					: '请选择',"value":_vm.ruleData.value[item.index]},on:{"change":(newValue) => _vm.valueChange(item.index, newValue)}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),(_vm.ruleData.items[item.index].length)?_vm._l((_vm.ruleData.items[item.index]),function(oitem){return _c('a-select-option',{key:oitem.value,attrs:{"value":oitem.value}},[_vm._v(" "+_vm._s(oitem.option)+" ")])}):_vm._e()],2):(item.type == 'multiSelect')?_c('a-select',{key:index,staticClass:"rule-input-select",attrs:{"mode":"multiple","placeholder":_vm.ruleData.items[item.index].length &&
				_vm.ruleData.items[item.index][0].value == ''
					? _vm.ruleData.items[item.index][0].option
					: '请选择',"value":_vm.ruleData.value[item.index]},on:{"change":(newValue) => _vm.valueChange(item.index, newValue)}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),(_vm.ruleData.items[item.index].length)?_vm._l((_vm.ruleData.items[item.index]),function(oitem){return _c('a-select-option',{key:oitem.value,attrs:{"value":oitem.value}},[_vm._v(" "+_vm._s(oitem.option)+" ")])}):_vm._e()],2):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }