<script>
	export default {
		name: 'AlarmRuleDetail',
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			formData: {
				type: Object
			}
		},
		methods: {
			btnCancel() {
				this.$emit('close')
			}
		}
	}
</script>

<template>
	<a-drawer
		title="预警规则详情"
		:width="480"
		:closable="false"
		:maskClosable="true"
		:visible="visible"
		@close="btnCancel"
	>
		<a-form
			:colon="false"
			:labelCol="{ span: 6 }"
			:wrapperCol="{ span: 18 }"
			:labelAlign="'left'"
		>
			<a-form-item label="预警名称">
				<label class="form-label" v-if="formData">
					{{ formData.warnName }}
				</label>
			</a-form-item>
			<a-form-item label="碰撞预警规则">
				<label
					class="form-label"
					v-if="formData"
					v-html="formData.warnRuleTemplate"
				>
				</label>
			</a-form-item>
			<a-form-item label="研判通知">
				<label class="form-label" v-if="formData">
					{{ formData.warnNotify }}
				</label>
			</a-form-item>
			<a-form-item label="预警类型">
				<label class="form-label" v-if="formData">
					{{ formData.typeName }}
				</label>
			</a-form-item>
			<a-form-item label="预警等级">
				<label class="form-label" v-if="formData">
					{{ formData.levelName }}
				</label>
			</a-form-item>
			<a-form-item label="组织架构">
				<label class="form-label" v-if="formData">
					{{ formData.orgName }}
				</label>
			</a-form-item>
		</a-form>
		<div class="close-button">
			<i class="ali-icon ali-icon-action-close" @click="btnCancel"></i>
		</div>
	</a-drawer>
</template>
